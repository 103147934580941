<template>
    <div class="vz-col" :class="colls">
        <slot />
    </div>
</template>

<script setup lang="ts">
import { KeyValueObject } from "~/types/types"

const props = defineProps({
    lg: { type: [Number, String], default: '' },
    md: { type: [Number, String], default: '' },
    sm: { type: [Number, String], default: '' },
    xs: { type: [Number, String], default: '' },
    col: { type: [Number, String], default: '' },
    offset: { type: [Number, String], default: '' },
    offsetXs: { type: [Number, String], default: '' },
    offsetSm: { type: [Number, String], default: '' },
    offsetMd: { type: [Number, String], default: '' },
    offsetLg: { type: [Number, String], default: '' }
})

const colls = computed((): KeyValueObject => {
    return {
        [`vz-col-${props.col}`]: props.col,
        [`lg-${props.lg}`]: props.lg,
        [`md-${props.md}`]: props.md,
        [`sm-${props.sm}`]: props.sm,
        [`xs-${props.xs}`]: props.xs,
        'vz-offset': props.offset,
        [`vz-offset-lg-${props.lg}`]: props.offsetLg,
        [`vz-offset-md-${props.md}`]: props.offsetMd,
        [`vz-offset-sm-${props.sm}`]: props.offsetSm,
        [`vz-offset-xs-${props.xs}`]: props.offsetXs

    }
})
</script>
